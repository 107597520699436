import React, { Component } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./styles/NavMenu2.css";
import { UnreadLeads, UnreadTasks, Version } from "./notifications/NavNotifications";
import GlobalSearch from "./reusable/globalsearch/GlobalSearch";

const logout = () => {
  localStorage.clear();
};

const userId = window.localStorage.getItem("user");
var reports = window.localStorage.getItem("reports");
const id = window.localStorage.getItem("id");
const initials = window.localStorage.getItem("initials");
const username = window.localStorage.getItem("username");
const masq = window.localStorage.getItem("masq");
var today = new Date();
var yyyy = today.getFullYear();
const hassalesbonus = window.localStorage.getItem("hassalesbonus");

export class NavMenu extends Component {

  static displayName = NavMenu.name;


  render() {
    return (
      <header style={{ backgroundColor: "white" }}>
        <Version />
        <Navbar style={{backgroundColor: username !== "kens" ? "white" : "hotpink"}} collapseOnSelect expand="xl" className="navbar-expand-lg navbar-toggleable-md ng-white border-bottom box-shadow">
          <Container fluid>
            <Navbar.Brand href="/" style={{ marginRight: "0px" }}>
              <input type="image" className="d-xxl-none" style={{ width: "46px", height: "42.7px", paddingTop: "0px", paddingLeft: "1px" }} src={require("../images/icon50.png")} alt={"company"} />
              <input type={((window.location.origin === (`https://spectronicsapollo.com`))) ? "image" : "hidden"} className="d-none d-xxl-block" style={{ width: "270px", height: "46.8px" }} src={require("../images/apollo300.png")} alt={"company"} />
              <input type={((window.location.origin === (`https://localhost:44309`))) ? "image" : "hidden"} className="d-none d-xxl-block" style={{ width: "270px", height: "46.8px" }} src={require("../images/apollo300test.png")} alt={"company"} />
              <input type={((window.location.origin === (`https://play.spectronicsapollo.com`))) ? "image" : "hidden"} className="d-none d-xxl-block" style={{ width: "270px", height: "46.8px" }} src={require("../images/apollo300test.png")} alt={"company"} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" style={{ textAlign: "center", zIndex: 2, fontSize: "1.1rem" }}>
              <Nav className="me-auto"> </Nav>
              <Nav>

              <span className='d-md-block d-lg-none'>
              <Nav.Link href="/globalsearch" target="_blank" rel="noopener noreferrer" style={{ fontWeight: "bold" }}>🔍</Nav.Link>
                </span>

                <span className='d-xl-block d-none' style={{marginTop: "-8px"}}>
                <GlobalSearch />
                </span>
                <Nav.Link style={{ fontWeight: "bold" }} className="dropdown" href="/">Home</Nav.Link>

                {/* title needs everything to be in an <a></a> tag */}

                <NavDropdown href="/usertasks" title={<span style={{ fontWeight: "bold" }}>Tasks<UnreadTasks /></span>}>
                  <NavDropdown.Item href="/usertasks">Your Tasks</NavDropdown.Item>
                  <NavDropdown.Item href="/createdtasks">Created Tasks</NavDropdown.Item>
                  <NavDropdown.Item href="/followfeed">Followed Tasks</NavDropdown.Item>
                  <NavDropdown.Item href="/viewtasks">All Tasks</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown hidden style={{ fontWeight: "bold" }} title={"Cases"} id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/cases">All Cases</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title={<span style={{ fontWeight: "bold" }}> Leads <UnreadLeads /> </span>} id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/usersleads">Your Leads</NavDropdown.Item>
                  <NavDropdown.Item href="/leads">All Leads</NavDropdown.Item>
                  <NavDropdown.Item href="/historic_crm_leads">All Historic Leads</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown style={{ fontWeight: "bold" }} title={"Opportunities"}>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/usersopportunities"> Your Opportunities </NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/allopportunities"> All Opportunities </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="/accounts" style={{ fontWeight: "bold" }}> Accounts </Nav.Link>

                {reports === "true" ? (
                  <NavDropdown className="dropdown" href="/reports" style={{ fontWeight: "bold" }} title={"Reports"}>
                    {userId === "ADMIN" || userId === "MARKETING" || userId === "OPERATIONS" || userId === "MANAGEMENT" || userId === "MIS" ? (
                      <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/customerinvoicereport"> Customer Invoice History </NavDropdown.Item>
                    ) : null}
                    {userId === "SALES" ? (
                      <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/salescustomerinvoicereport"> Customer Invoice History </NavDropdown.Item>
                    ) : null}
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/marketreport"> All Sales by Market </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/marketreport?territory=D">  Domestic Market Sales History </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/marketreport?territory=I"> International Market Sales History </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/productreport"> All Product Sales History </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/productreport?territory=I"> International Product Sales History </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/productreport?territory=D"> Domestic Product Sales History </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/discountoverviewinternational"> International Discounts </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/discountoverviewdomestic"> Domestic Discounts </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/youropenorders"> Your Open Orders </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/openorders"> All Open Orders </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/recentinvoice"> Recent Invoices </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/customersegmentsales">Customer Segment Sales</NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/salespivot"> Sales Pivot </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href={`/marketsalesbymonth/?territory=A&year=${yyyy}`}>Market Sales by Month </NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href={`/consolidatedsalesreport?type=ALL&year=${yyyy}`}>Consolidated Sales</NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href={"/customersalesbyyear?initials=__&sm=N"}> Customer Sales By Year</NavDropdown.Item>
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href={"/customersalesbyyear?initials="+initials+"&sm=Y"}> Your Customer Sales By Year</NavDropdown.Item>
                  </NavDropdown>
                ) : null}

              {(hassalesbonus === "true") ? (
                <NavDropdown style={{ fontWeight: "bold" }} title={"Commissions"}>
               {(hassalesbonus === "true") && (userId === "ADMIN")  ? (
                  <NavDropdown.Item href="/commissionsadmin">All Commissions</NavDropdown.Item>
                  ) : null}

                {(hassalesbonus === "true")  ? (
                  <NavDropdown.Item href={`/commissions/${username}`}>Your Commissions</NavDropdown.Item>
                  ) : null}
                </NavDropdown>
                 ) : null}

                <NavDropdown className="dropdown" style={{ fontWeight: "bold" }} title={"Resources"}>
                  <NavDropdown.Item href="/viewcustomers"> Customers </NavDropdown.Item>
                  <NavDropdown.Item href="/customerorders"> Customer Orders </NavDropdown.Item>
                  <NavDropdown.Item href="/parts"> Parts </NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/edi/orders"> EDI Orders </NavDropdown.Item>
                  <NavDropdown.Item href="/pricelists">Standard Price List</NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/privatelabels"> Private Label Price List </NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/custompricing"> Custom Pricing </NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/discountoverviewinternational"> International Discounts </NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/discountoverviewdomestic"> Domestic Discounts </NavDropdown.Item>
                  {userId === "ADMIN" || userId === "MARKETING" || userId === "QUALITY" || id === "66" || id === "72" || id === "55" || id === "53" ? (
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/marketingtools"> Marketing Tools </NavDropdown.Item>
                  ) : null}

                  {userId === "ADMIN" || userId === "FINANCE" ? (
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/rmaprice">RMA Price</NavDropdown.Item>
                  ) : null}

                  {userId === "ADMIN" || id === "53" || id === "83" ? (
                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/rebatelist">Rebate List</NavDropdown.Item>
                  ) : null}

                    <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href={`/forecast/todo/?user=${username}`}>Forecasting To-Do</NavDropdown.Item>

                </NavDropdown>

                {userId === "ADMIN" ? (
                  <NavDropdown href="/allusers" style={{ fontWeight: "bold" }} title={"Admin"}>
                    <NavDropdown.Item href="/createuser">Create User</NavDropdown.Item>
                    <NavDropdown.Item href="/edituser">Edit Passwords</NavDropdown.Item>
                    <NavDropdown.Item href="/admin/users">View All Users</NavDropdown.Item>
                    <NavDropdown.Item href="/stockingorders">Stocking Orders</NavDropdown.Item>
                    <NavDropdown.Item href="/customerdiscounteditor">Customer Discount Editor</NavDropdown.Item>
                    <NavDropdown.Item href="/forecastcontrolpanel/2024">Forecast Control Panel</NavDropdown.Item>
                    <NavDropdown.Item href="/grouplist">Comment Tagging Groups</NavDropdown.Item>
                    <NavDropdown.Item href="/commissionsadmin">Commissions</NavDropdown.Item>
                    <NavDropdown.Item href="/admintools">Admin Tools</NavDropdown.Item>
                    <NavDropdown.Item href="/devtools">Dev Tools</NavDropdown.Item>
                    <NavDropdown.Item href="/masterlog">Master Log</NavDropdown.Item>
                  </NavDropdown>
                ) : null}

                <NavDropdown align="end" className="dropdown" href="/all" style={{ fontWeight: "bold" }} title={"All"}>
                  <NavDropdown.Item href="/sourcepassticket">CRM Feedback</NavDropdown.Item>
                  <NavDropdown.Item href="/viewtasks"> Tasks </NavDropdown.Item>
                  <NavDropdown.Item href="/leads"> Leads </NavDropdown.Item>
                  <NavDropdown.Item href="/quotes">All Quotes</NavDropdown.Item>
                  <NavDropdown.Item href="/allopportunities">Opportunities</NavDropdown.Item>
                  <NavDropdown.Item href="/viewalldigitalassets">All Digital Assets</NavDropdown.Item>
                  <NavDropdown.Item href="/viewtrips">Trips</NavDropdown.Item>
                  <NavDropdown.Item href="/meetings">Meetings</NavDropdown.Item>
                  <NavDropdown.Item href="/allpricerequests">All Price Requests</NavDropdown.Item>
                  <NavDropdown.Item href="/accounts">Accounts</NavDropdown.Item>
                  <NavDropdown.Item href="/parts">Visual Parts</NavDropdown.Item>
                  <NavDropdown.Item href="/pricelists">Standard Price List</NavDropdown.Item>
                  <NavDropdown.Item style={{ whiteSpace: "nowrap" }} href="/privatelabels"> Private Label Price List </NavDropdown.Item>
                  <NavDropdown.Item href="/viewcustomers">Visual Customers</NavDropdown.Item>
                  <NavDropdown.Item href="/allusers">CRM Users</NavDropdown.Item>
                  <NavDropdown.Item href="/historic_crm/">Historic CRM</NavDropdown.Item>
                  <NavDropdown.Item  hidden href="/latest">Latest CRM Changes</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown align="end" title={<img src={"/icons/Gear32.png"} alt="line" />} style={{ marginRight: "0px" }}>
                {(hassalesbonus === "true") && (userId === "ADMIN")  ? (
                  <NavDropdown.Item href={`/profile/${username}`}>Profile</NavDropdown.Item>
                  ) : null}
                  <NavDropdown.Item href="/changepassword">Change Password</NavDropdown.Item>
                  <NavDropdown.Item href="/login" onClick={() => logout(false)} to="/login"> Log Out </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
